@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.last-packs {
  width: 100vw;

  &__header {
    margin-top: 16px;
    margin-left: 28px;
  }

  &__title {
    margin-bottom: 32px;

    @include media(">tablet") {
      margin-bottom: 42px;
      max-width: 60%;
    }
  }

  &__categories {
    display: flex;
    gap: 12px;
    overflow: scroll;
    margin-bottom: 12px;

    &::-webkit-scrollbar{
      display: none;
    }

    &__category {
      min-width: auto !important;
    }
  }

  &__packs {
    display: flex;
    overflow: scroll;
    gap: 12px;
    scroll-snap-type: x mandatory;
    margin-top: 24px;

    &::-webkit-scrollbar{
      display: none;
    }
  }

  &__pack {
    min-width: 60%;
    max-width: 400px;
    scroll-snap-align: center;

    @include media(">small-tablet") {
      min-width: 40%;
    }

    @include media(">tablet") {
      scroll-snap-align: start;
      min-width: 30%;
    }

    &:first-child {
      padding-left: 8px;
    }

    &:last-child {
      padding-right: 8px;
    }
  }
}
