@import "styles/theme/colors.scss";
@import "styles/resources/mixins.scss";

.sign-up-widget {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 500px;
  width: 100%;

  &__bg-image {
    width: 100%;
    object-fit: cover;
    height: 80vh;
  }

  &__form-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    background: white;
    padding: 28px;
    border-radius: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 80vw;
    max-width: 360px;

    &--hidden {
      display: none;
    }

    @include media(">desktop") {
      left: 20%;
      transform: none;
      width: auto;
    }
  }

  &__title {
    color: rgba(0,0,0,0.87);
    direction: ltr;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    margin-bottom: 28px;
    padding: 0;
  }

  &__form {
    width: 100%;
    max-width: 360px;

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 22px;
      margin-bottom: 32px;

      @include media(">tablet") {
        gap: 32px;
      }
    }

    &__action-buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
      width: 100%;
    }

    &__submit-btn {
      width: 100%;
    }
  }

  &__description {
    font-size: 12px;
    color: grey;
    margin-top: 0;
    margin-bottom: 16px;
  }

  &__sign-in-link {
    font-size: 0.8rem;
  }

  &__loading {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
  }
}
