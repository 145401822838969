@import "styles/resources/mixins.scss";

.start-ease {
  padding: 16px 0;

  &__title {
    padding: 0 10px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;

    @include media(">desktop") {
      margin-top: 0;
      padding: 0;
    }
  }

  &__grid {
    padding: 0;
    max-width: 800px;
  }

  &__item {
    text-align: center;
    padding: 20px;

    @include media(">desktop") {
      text-align: left;
    }

    &__icon {
      font-size: 4rem !important;
    }

    &__title {
      text-align: left;
    }

    &__sub-title {
      white-space: pre-line;
      text-align: left;
      margin-top: 8px;
    }

    &__content {
      white-space: pre-line;
      text-align: left;
      margin-top: 8px;
    }
  }
}
