@import "styles/resources/mixins.scss";

.title-image-side-by-side {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(">tablet") {
    flex-direction: row;
  }

  &__titles {
    padding: 5px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;

    @include media(">tablet") {
      width: 50%;
      padding: 30px;
      text-align: left;
    }
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    width: 80%;
    height: 100%;
  }

  &__image {
    border-radius: 12px;

    img {
      object-fit: contain;
    }

    @include media(">tablet") {
      width: 50%;
      min-width: 50%;
    }
  }

  &__sub-title {
    margin-top: 16px;
    margin-bottom: 16px;

    @include media(">tablet") {
      margin-bottom: 0;
    }
  }

  &__action-button-wrapper {
    display: none;

    @include media(">tablet") {
      display: block;
      margin-top: 16px;
      margin-right: 100%;
    }
  }

  &__action-button-wrapper-mobile {
    display: block;
    margin-top: 16px;

    @include media(">tablet") {
      display: none;
    }
  }
}
