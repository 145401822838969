.button {
  border-radius: 8px;

  &--x-large {
    padding: 12px 24px !important;
    font-size: 0.9rem !important;
  }

  &--x-wide {
    padding: 4px 48px !important;
  }

  &--x-wide-large {
    padding: 8px 48px !important;
    font-size: 0.9rem !important;
  }
}

.icon-button {
  &__bottom-text {
    margin-top: 16px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  
    &__text {
      margin-top: 6px;
      font-size: 0.7rem;
      text-align: center;
    }
  }
}