@import "styles/resources/mixins.scss";
@import "styles/theme/colors.scss";

.home {
  @include media(">tablet") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__box {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    padding: 0 8px;

    @include media(">tablet") {
      padding: 0;
      margin-bottom: 32px;
    }

    &--fullscreen {
      width: 100%;
      padding: 0;

      @include media(">tablet") {
        padding: 0;
      }
    }

    &--primary-color {
      background-color: $background__primary-color;
    }

    &--secondary-color {
      background-color: $background__secondary-color;
    }

    &--last {
      margin-bottom: 0;
    }
  }

  &__hero {
    width: 100%;
    padding: 16px;

    @include media(">tablet") {
      padding: 40px;
    }
  }

  &__fullscreen-image {
    width: 100%;
  }

  &__simple-text-cta {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 24px;
  }
}
